import React from 'react';
import s from "../../pages/main/content/styles.module.css";
import {ReactComponent as Arrow} from "../../assetes/arrow.svg";
import {useNavigate} from "react-router-dom";

const HeaderTitle = ({isArrow = true}) => {
  const navigate = useNavigate()
  return (
    <div className={s.header}>
      <h2><Arrow onClick={() => navigate('/')} style={{
        height: '40px',
        width: 'auto',
        marginRight: '5px',
        display: isArrow ? 'block' : 'none',
        cursor: 'pointer'
      }}/>Consigue préstamo al instante online</h2>
      <p></p>
    </div>
  );
};

export default HeaderTitle;
