import React from 'react';
import s from './styles.module.css'
import Wrapper from '../../components/wrapper';
import HeaderTitle from '../../components/header_title';

const AboutMfo = () => {
  return (
    <div className={s.about}>
      <Wrapper>
        <HeaderTitle/>
        <br/>

        <p className={s.text}>
          <div><b>loan.es</b></div>
        </p>


        <br/>

      </Wrapper>
    </div>
  );
};

export default AboutMfo;
