import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const Advice = () => {
  return (
    <div className={s.advice}>
      <Wrapper>
        <HeaderTitle/>
        <br/>
        <h2>¿Se pueden conseguir microcréditos sin intereses?</h2>
        <p className={s.text}>Sí. Como hemos comentado anteriormente se pueden conseguir préstamos al 0% de interés (TAE). Para hacerlo tendrás que ser un nuevo cliente de la entidad y ceñirte al importe máximo y al plazo de devolución que la entidad te exija.</p>
        <h2>¿Cuánto tiempo tardaré en obtener mi minicrédito?</h2>
        <p className={s.text}>Normalmente es un proceso rápido, las solicitudes suelen tramitarse el mismo día y el ingreso del dinero en la cuenta puede hacerse tan solo 15 minutos después de la aprobación.</p>
        <h2>¿Cuánto dinero puedo conseguir con los minicréditos?</h2>
        <p className={s.text}>La cantidad de dinero que podrás obtener con los microcréditos no es muy elevada. Normalmente suelen ser mil euros.</p>
        <h2>¿Qué pasa si no puedo devolver un microcrédito?</h2>
        <p className={s.text}>No devolver un microcrédito puede tener serias consecuencias, desde el incremento del coste total del préstamo hasta la inclusión del solicitante en la lista ASNEF. Cuando sientas que no vas a poder pagar una cuota a tiempo puedes ponerte en contacto con la entidad para conseguir un aplazamiento. De esta manera evitarías que se te cobraran comisiones por mora o por aviso de impago, que encarecerían el coste total de tu préstamo pudiendo hacer más difícil su devolución.</p>
      </Wrapper>
    </div>
  );
};

export default Advice;
