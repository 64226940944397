import React from 'react';
import s from './styles.module.css'
import Wrapper from "../wrapper";
import {Link, useLocation} from "react-router-dom";

const Footer = () => {
    const {pathname} = useLocation()
    return (
        <div className={s.footer}>
            <Wrapper>
                <div className={s.content}>
                    <h3>MICROCRÉDITOS RÁPIDOS EN ESPAÑA</h3>
                    <div className={s.navigate}>
                        <Link to={'/about'} style={{color: pathname === '/about' && '#039be5'}}>Sobre nosotros</Link>
                        <Link className={s.second} to={'/advice'} style={{color: pathname === '/advice' && '#039be5'}}>Consejos</Link>
                        <Link className={s.second} to={'/conditions'} style={{color: pathname === '/conditions' && '#039be5'}}>Requisitos básicos</Link>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Footer;
