import React, {useState} from 'react';
import s from './styles.module.css'
import ReactCardFlip from "react-card-flip";

const Item = ({_id, age, amount, date, logo, text, firstLoanFree}) => {
    const [isFliper, setIsFliped] = useState(false)
    const handleClick = () => {
        setIsFliped(!isFliper)
    }
    let matches = document.cookie.match(new RegExp("(?:^|; )clickId=([^;]*)"));
    let clickId = matches ? matches[1] : '12345';
    matches = document.cookie.match(new RegExp("(?:^|; )sa=([^;]*)"));
    let sa = matches ? matches[1] : 'promo-no';
    let url = '/api/clicks/credit/' + _id + '?sa=' + sa + '&sa2=' + clickId;
    return (
        <ReactCardFlip isFlipped={isFliper} flipDirection="horizontal">
            <a href={url} target="_blank" rel="noreferrer">
                <div className={s.item}>
                    <div className={s.header_item}>
                        <h3>{text}</h3>
                        <div className={s.img_box}>
                            {logo ? <img src={logo} alt="logo"/> : null}
                        </div>
                        <div className={s.show} onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleClick()
                        }}>
                            i
                        </div>
                    </div>

                    <div className={s.content_item}>
                        <div className={s.content_item_item}>
                            <p className={s.qest}>Importe</p>
                            <p className={s.answer}> {amount}</p>
                        </div>
                        <div className={s.content_item_item}>
                            <p className={s.qest}>Plazo</p>
                            <p className={s.answer}> {date}</p>
                        </div>
                    </div>

                    <div className={s.btn}><span>Solicitar préstamo</span></div>
                </div>
            </a>
            <a href={url} target="_blank" rel="noreferrer">
                <div className={s.item}>
                    <div className={s.header_item}>
                        {/*<h3></h3>*/}
                        {/*<div className={s.img_box}>{logo ? <img src={logo} alt="logo"/> : null}</div>*/}
                        <div className={s.show} onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleClick()
                        }}>
                            i
                        </div>
                    </div>

                    <div className={s.content_item2}>
                        <div className={s.content_item_item}>
                            <p className={s.qest}>Importe</p>
                            <p className={s.answer}> {amount}</p>
                        </div>
                        <div className={s.content_item_item}>
                            <p className={s.qest}>Plazo</p>
                            <p className={s.answer}> {date}</p>
                        </div>
                        <div className={s.content_item_item}>
                            <p className={s.qest}>Edad</p>
                            <p className={s.answer}> {age}</p>
                        </div>
                        {firstLoanFree && <div className={s.box_text} style={{width: '100%'}}>
                            <p className={s.text}>Primer préstamo 0%</p>
                        </div>}
                    </div>

                    <div className={s.btn}>
                        <span>Solicitar préstamo</span>
                    </div>
                </div>
            </a>
        </ReactCardFlip>


    );
};

export default Item;
