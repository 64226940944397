import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import HeaderTitle from "../../components/header_title";

const About = () => {
  return (
    <div className={s.about}>
      <Wrapper>
        <HeaderTitle/>
        <br/>
        <p className={s.text}>Creemos que conseguir financiación no debería ser complicado.</p>
        <p className={s.text}>Para ver ofertas de préstamos, vaya a la página principal.</p>
        <p className={s.text}>El sitio no emite créditos ni préstamos, es puramente informativa.</p>
        <p className={s.text}>Aquí puede encontrar las ofertas más rentables con filtros convenientes.</p>
        <p className={s.text}>Comparamos entre los productos más competitivos del mercado.</p>
        <p className={s.text}>Trabajamos en beneficio del cliente y de forma gratuita.</p>
        <p className={s.text}>Todas las instituciones de crédito recomendadas en el sitio tienen las licencias adecuadas.</p>
      </Wrapper>
    </div>
  );
};

export default About;
